import React, { Component } from 'react';
import PopUp from "./PopUp";

export class MenuButton extends Component {
    state = {
        selected: false,
    }

    onSelect = (e) => this.setState({selected: true});

    closePopUp = (e) => {
        let popUpBackground = document.querySelector(".pop-up-background");
  
        if(e.target === popUpBackground) {
        this.setState({selected: false});
        }
    }

    render() {
        if(this.state.selected) {
            return (                
                <div>
                    <button onClick={this.onSelect}>{ this.props.title }</button>;
                    <PopUp content={ this.props.title } tab={"QUALITY CONTROL"} closePopUp={ this.closePopUp }/>
                </div>
            )
        }

        return (
            <div>
                <button onClick={this.onSelect}>{ this.props.title }</button>
            </div>
        );
    }
}

export default MenuButton;