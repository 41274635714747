import React, { Component } from 'react';

export class ExclusiveContent extends Component {
    constructor() {
        super();
        this.state = {
            success: false,
            timedOut: false,
            messageContent: '',
            messageStyle: {
                color: 'black'
            }
        }
    }

    requestOutcome = (timeLimit) => {
        setTimeout(() => {
            if(this.state.success === true) {
                console.log('Request successful!')
                this.setMessage('You\'ve been added successfully!', 'green');
            } else {
                this.setState({timedOut: true});
                console.error('Unsuccessful: request timed out.');
                this.setMessage('Something went wrong - please try again!', 'red');
            }
        }, timeLimit);
    };

    setMessage = (messageText, colour, resetTime = 2000) => {
        this.setState({messageContent: messageText});
        this.setState({messageStyle: {color: colour}});
        setTimeout(() => this.setState({messageContent: ''}), resetTime);
    }

    callPHP = (params) => {
        var httpc = new XMLHttpRequest();
        var url = "http://eskafell.com/subscribe.php";
        httpc.requestSuccess = false;

        httpc.open("POST", url, true);
    
        httpc.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    
        httpc.onreadystatechange = function() {
            if(httpc.readyState === 4 && httpc.status === 200) {
                if(httpc.responseText === "success"){
                    this.requestSuccess = true;
                }
            }
        };
    
        setTimeout(() => {
            if(httpc.requestSuccess === true) {
                this.setState({success: true});
            }
        }, 500);

        httpc.send(params);
    }

    callPHPTest = (e) => {
        var emailInput = document.querySelector("#email");

        //NEED TO COVER ALL INVALID EMAILS & DEAL WITH ERROR PROBLEM
        if(emailInput.value === "") {
            this.setMessage('Please enter a valid email address.', 'red');
            console.error('Unsuccessful: no valid email address provided.');
        } else{
            this.callPHP("user_email="+emailInput.value);
            this.requestOutcome(1000);

            //Clear fields
            emailInput.value = "";
        }
    }

    render() {
        return (
                <div id="mailing-list-sign-up" style={formStyle}>
                    <p>Sign up to the mailing list to recieve exclusive content.</p>
                    <div id="message" style={this.state.messageStyle}><p>{this.state.messageContent}</p></div>
                    <div>
                        Email: <input type="text" id="email"/> <br/><br/>
                    </div>
                    <input id="submit-button" onClick={this.callPHPTest} type="submit" value="Submit"/>
                </div>
        );
    }
}

const formStyle = {
    position: "absolute",
    margin: "3%",
}

export default ExclusiveContent;