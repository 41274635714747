import React, { Component } from 'react';
import appleMusic from "../images/US-UK_Apple_Music_lockup_all-blk.png";
import spotify from "../images/Spotify_Logo_CMYK_Black.png";
import amazonMusic from "../images/amazon_music.png";
import intro from "../images/quality_control_cover.jpg";

const wrapperStyle = {
    position: "absolute",
    width: "100%"
}

const artworkStyle = {
    position: "relative",
    width: "250px",
    top: "25px",
    boxShadow: "3px 3px 3px",
}

const linkWrapperStyle = {
    position: "relative",
    width: "250px",
    left: "60px",
    top: "50px",
}

export class QualityControlLinks extends Component {
    render() {
        return (
            <div id="content-wrapper">
                        
                <div id="intro" style={wrapperStyle}>
                    <img src={intro} alt="Quality Control artwork" style={artworkStyle}></img>
                    <ul id="quality-control-link-wrapper" style={linkWrapperStyle}>
                        <li><a href="https://music.apple.com/gb/album/quality-control-single/1526185927"><img src={appleMusic} alt="Apple Music logo"></img></a></li>
                        <li><a href="https://open.spotify.com/album/4bpUYjQMnr56KCDkKI4f4o?si=m3nGU1DmRSCviuhq2WmwKA"><img src={spotify} alt="Spotify logo"></img></a></li>
                        <li style={{borderBottom:"none"}}><a href="https://music.amazon.co.uk/albums/B08F784GB2"><img src={amazonMusic} alt="Amazon Music logo"></img></a></li>
                    </ul>
                </div>
            </div>
        );
    }
}


export default QualityControlLinks;