import React, { Component } from 'react';
import TabBar from "./TabBar";
import QualityControlLinks from "./QualityControlLinks";
import TwistedFruitLinks from "./TwistedFruitLinks";
import GothamLinks from "./GothamLinks";
import IntroLinks from "./IntroLinks";

export class MusicLinks extends Component {
    constructor() {
        super();
        this.state = {
            tab: "null"
        }
        this.updateTab = this.updateTab;
    }

    updateTab = (text) => this.setState({ tab: text });

    render() {
        if(this.state.tab === "null"){
            if(this.props.tab === "QUALITY CONTROL") {
                return (
                    <div>
                        <TabBar open={"QUALITY CONTROL"} updateTabCallback={this.updateTab}/>
                        <QualityControlLinks/>
                    </div>
                    
                );
            }

            if(this.props.tab === "TWISTED FRUIT") {
                return (
                    <div>
                        <TabBar open={"TWISTED FRUIT"} updateTabCallback={this.updateTab}/>
                        <TwistedFruitLinks/>
                    </div>
                    
                );
            }

            if(this.props.tab === "GOTHAM") {
                return (
                    <div>
                        <TabBar open={"GOTHAM"} updateTabCallback={this.updateTab}/>
                        <GothamLinks/>
                    </div>
                    
                );
            }
    
            if(this.props.tab === "INTRO") {
                return(
                    <div>
                        <TabBar open={"INTRO"} updateTabCallback={this.updateTab}/>
                        <IntroLinks/>
                    </div>
                );
            }
        } else if(this.state.tab === "QUALITY CONTROL"){
            return (
                <div>
                    <TabBar open={"QUALITY CONTROL"} updateTabCallback={this.updateTab}/>
                    <QualityControlLinks/>
                </div>
            );
        } else if(this.state.tab === "TWISTED FRUIT"){
            return (
                <div>
                    <TabBar open={"TWISTED FRUIT"} updateTabCallback={this.updateTab}/>
                    <TwistedFruitLinks/>
                </div>
            );
        } else if(this.state.tab === "GOTHAM"){
            return (
                <div>
                    <TabBar open={"GOTHAM"} updateTabCallback={this.updateTab}/>
                    <GothamLinks/>
                </div>
            );
        } else if(this.state.tab === "INTRO"){
            return(
                <div>
                    <TabBar open={"INTRO"} updateTabCallback={this.updateTab}/>
                    <IntroLinks/>
                </div>
            );
        }
        
    }
}


export default MusicLinks;