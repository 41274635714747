import React, { Component } from 'react';
import appleMusic from "../images/US-UK_Apple_Music_lockup_all-blk.png";
import spotify from "../images/Spotify_Logo_CMYK_Black.png";
import amazonMusic from "../images/amazon_music.png";
import gotham from "../images/gotham_cover_final.jpg";

const wrapperStyle = {
    position: "absolute",
    width: "100%"
}

const artworkStyle = {
    position: "relative",
    width: "250px",
    top: "25px",
    boxShadow: "3px 3px 3px",
}

const linkWrapperStyle = {
    position: "relative",
    width: "250px",
    left: "60px",
    top: "50px",
}

export class GothamLinks extends Component {
    render() {
        return (
            <div id="content-wrapper">
                
                <div id="gotham" style={wrapperStyle}>
                    <img src={gotham} alt="Gotham artwork" style={artworkStyle}></img>
                    <ul id="gotham-link-wrapper" style={linkWrapperStyle}>
                        <li><a href="https://music.apple.com/gb/album/gotham-single/1490340319"><img src={appleMusic} alt="Apple Music logo"></img></a></li>
                        <li><a href="https://open.spotify.com/album/1OWPow6vW8ZZMuR1GCxg1M?si=dxDIXsKARHeScRPCiY7SDw"><img src={spotify} alt="Spotify logo"></img></a></li>
                        <li style={{borderBottom:"none"}}><a href="https://music.amazon.com/albums/B082CNZ1CX"><img src={amazonMusic} alt="Amazon Music logo"></img></a></li>
                    </ul>
                </div>
            </div>
        );
    }
}


export default GothamLinks;