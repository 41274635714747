import React, { Component } from 'react';

const spotifyPlayerStyle = {
    position: "relative",
    top: "0px",
    left: "0px",
    margin: "0px",
    padding: "0px",
}

export class SpotifyPlayer extends Component {
    render() {
        return (
            //SMALL PLAYERS
            
            //Quality Control
            <iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/album/4bpUYjQMnr56KCDkKI4f4o" width="280px" height="80px" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Twisted Fruit
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/album/794ACiYqwoCod193oun3l1" width="280px" height="80px" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Gotham
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/track/4ZF5savwurIdQxFhcTkbyy" width="280px" height="80px" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Intro
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/track/5Skh94gF4HfEclITUaF7ZP" width="280px" height="80px" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>

            //BIG PLAYERS

            //Quality Control
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/album/4bpUYjQMnr56KCDkKI4f4o" width="280" height="270" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Twisted Fruit
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/album/794ACiYqwoCod193oun3l1" width="280" height="270" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Gotham
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/track/4ZF5savwurIdQxFhcTkbyy" width="280" height="360" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            //Intro
            //<iframe style={spotifyPlayerStyle} title="spotify-player" src="https://open.spotify.com/embed/track/5Skh94gF4HfEclITUaF7ZP" width="280px" height="360px" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        );
    }
}

export default SpotifyPlayer;