import React, { Component } from 'react';
import appleMusic from "../images/US-UK_Apple_Music_lockup_all-blk.png";
import spotify from "../images/Spotify_Logo_CMYK_Black.png";
import amazonMusic from "../images/amazon_music.png";
import intro from "../images/intro_cover.jpg";

const wrapperStyle = {
    position: "absolute",
    width: "100%"
}

const artworkStyle = {
    position: "relative",
    width: "250px",
    top: "25px",
    boxShadow: "3px 3px 3px",
}

const linkWrapperStyle = {
    position: "relative",
    width: "250px",
    left: "60px",
    top: "50px",
}

export class IntroLinks extends Component {
    render() {
        return (
            <div id="content-wrapper">
                        
                <div id="intro" style={wrapperStyle}>
                    <img src={intro} alt="Intro artwork" style={artworkStyle}></img>
                    <ul id="intro-link-wrapper" style={linkWrapperStyle}>
                        <li><a href="https://music.apple.com/gb/album/intro-single/1486622707"><img src={appleMusic} alt="Apple Music logo"></img></a></li>
                        <li><a href="https://open.spotify.com/album/5hSTldzO1hL99ujpV9P672?si=PEYdCE6XRPyZHACFh5IfMw"><img src={spotify} alt="Spotify logo"></img></a></li>
                        <li style={{borderBottom:"none"}}><a href="https://music.amazon.com/albums/B0813Y8TR4"><img src={amazonMusic} alt="Amazon Music logo"></img></a></li>
                    </ul>
                </div>
            </div>
        );
    }
}


export default IntroLinks;