import React, { Component } from 'react';
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import instagram from "../images/instagram.png";
import youtube from "../images/youtube.png";

export class Socials extends Component {
    render() {
        return (
            <div>
                <p>ENQUIRIES: contact@eskafell.com</p>
                        <ul id="socials">
                            <li><a href="https://www.facebook.com/eskafellsheff/" target="blank"><img src={facebook} alt="Facebook" width="40dp"/></a></li>
                            <li><a href="https://twitter.com/eskafell_sheff" target="blank"><img src={twitter} alt="Twitter" width="40dp"/></a></li>
                            <li><a href="https://www.instagram.com/eskafell_sheff/" target="blank"><img src={instagram} alt="Instagram" width="40dp"/></a></li>
                            <li><a href="https://www.youtube.com/channel/UCn_3W_uwQlo6AZri-YbUHCA" target="blank"><img src={youtube} alt="YouTube" height="40dp"/></a></li>
                        </ul>
            </div>
        );
    }
}

export default Socials;