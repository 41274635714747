import React, { Component } from 'react';

export class Store extends Component {
    render() {
        return (
            <div>
                <p style={textStyle}>coming soon...</p>
            </div>
        );
    }
}

const textStyle = {
    top: "20px",
}

export default Store;