import React, { Component } from 'react';

const openTab = {
    padding: "13px",
    color: "#000",
    //width: "40%",
    height: "40px",
    borderStyle: "none",
    borderWidth: "1px"
};

const closedTab = {
    padding: "13px",
    color: "#777777",
    //width: "30%",
    height: "40px",
    borderStyle: "none",
    borderRadius: "5px",
    borderTopStyle: "none",
    borderWidth: "1px",
    borderColor: "#B0B0B0"
};

export class TabBar extends Component {
    state = {
        selected: this.props.open,
    }

    qualityControlSelect = (e) => {
        this.props.updateTabCallback("QUALITY CONTROL");
    }

    twistedFruitSelect = (e) => {
        this.props.updateTabCallback("TWISTED FRUIT");
    }

    gothamSelect = (e) => {
        this.props.updateTabCallback("GOTHAM");
    }

    introSelect = (e) => {
        this.props.updateTabCallback("INTRO");
    }

    render() {
        if(this.props.open === "QUALITY CONTROL") {
            return (                
                <div id="tab-bar">
                    <button onClick={this.qualityControlSelect} style={openTab}>{ "QUALITY CONTROL" }</button>
                    <button onClick={this.twistedFruitSelect} style={closedTab}>{ "TWISTED FRUIT" }</button>
                    <button onClick={this.gothamSelect} style={closedTab}>{ "GOTHAM" }</button>
                    <button onClick={this.introSelect} style={closedTab}>{ "INTRO" }</button>
                </div>
            );
        }

        if(this.props.open === "TWISTED FRUIT") {
            return (                
                <div id="tab-bar">
                    <button onClick={this.qualityControlSelect} style={closedTab}>{ "QUALITY CONTROL" }</button>
                    <button onClick={this.twistedFruitSelect} style={openTab}>{ "TWISTED FRUIT" }</button>
                    <button onClick={this.gothamSelect} style={closedTab}>{ "GOTHAM" }</button>
                    <button onClick={this.introSelect} style={closedTab}>{ "INTRO" }</button>
                </div>
            );
        }

        if(this.props.open === "GOTHAM") {
            return (                
                <div id="tab-bar">
                    <button onClick={this.qualityControlSelect} style={closedTab}>{ "QUALITY CONTROL" }</button>
                    <button onClick={this.twistedFruitSelect} style={closedTab}>{ "TWISTED FRUIT" }</button>
                    <button onClick={this.gothamSelect} style={openTab}>{ "GOTHAM" }</button>
                    <button onClick={this.introSelect} style={closedTab}>{ "INTRO" }</button>
                </div>
            );
        }

        if(this.props.open === "INTRO") {
            return (                
                <div id="tab-bar">
                    <button onClick={this.qualityControlSelect} style={closedTab}>{ "QUALITY CONTROL" }</button>
                    <button onClick={this.twistedFruitSelect} style={closedTab}>{ "TWISTED FRUIT" }</button>
                    <button onClick={this.gothamSelect} style={closedTab}>{ "GOTHAM" }</button>
                    <button onClick={this.introSelect} style={openTab}>{ "INTRO" }</button>
                </div>
            );
        }
    }
}

export default TabBar;