import React, { Component } from 'react'

const wrapperStyle = {
    position: "absolute",
    width: "100%",
    height: "35px",
    margin: "0",
    bottom: "-0",
    left: "0",
    display: "block",
}

var footerBar = {
    position: "absolute",
    background: "#1f1f1f",
    width: "100%",
    padding: "0",
    margin: "0",
    bottom: "-0",
    left: "0",
    display: "none",
}

const footerText = {
    position: "relative",
    padding: "4px",
    margin: "0",
    textAlign: "right",
}

export class Footer extends Component {
    toggleShow = (e) => {
        if(document.getElementById("footer-bar").style.display === "none") {
            document.getElementById("footer-bar").style.display = "block";
        } else {
            document.getElementById("footer-bar").style.display = "none";
        }
    }

    render() {
            return(
                <div id='wrapper' onMouseEnter={this.toggleShow} onMouseLeave={this.toggleShow} style={wrapperStyle}>
                    <div id={"footer-bar"} style={footerBar}>
                        <p style={footerText}>Copyright &copy; 2020</p>
                    </div>
                </div>
            )
    }
}

export default Footer