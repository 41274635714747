import React, { Component } from 'react';
import Socials from "./Socials";
import MenuButton from "./MenuButton";
import SpotifyPlayer from './SpotifyPlayer';
import qualityControl from "../images/quality_control_cover.jpg";

const artworkStyle = {
    position: "relative",
    width: "280px",
    margin: "0",
    padding: "0",
    //top: "25px",
    //boxShadow: "3px 3px 3px",
}

class Menu extends Component {
	render() {
        return (
            //COMMENTED OUT IS THE OTHER BUTTONS

            /*<div id="menu">
                <ul>
                    <li style={{padding: "0"}}><SpotifyPlayer/></li>
                    <li><MenuButton title={"EXCLUSIVE CONTENT"}/></li>
                    <li><MenuButton title={"SHOWS"}/></li>
                    <li><MenuButton title={"LISTEN"}/></li>
                    <li style={{borderBottom:"none"}}><Socials/></li>
                </ul>
            </div>*/

            <div id="menu">
                <ul>
                    <li style={{whiteSpace: "pre-wrap"}}>      QUALITY CONTROL               OUT NOW!!</li>
                    <li style={{padding: "0"}}><img src={qualityControl} alt="Quality Control artwork" style={artworkStyle}></img></li>
                    <li style={{padding: "0"}}><SpotifyPlayer/></li>
                    <li><MenuButton title={"LISTEN"}/></li>
                    <li style={{borderBottom:"none"}}><Socials/></li>
                </ul>
            </div>
        );
  	}
}

export default Menu;