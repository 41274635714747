import React, { Component } from 'react';
import ExclusiveContent from "./ExclusiveContent";
import Shows from "./Shows";
import Store from "./Store";
import MusicLinks from "./MusicLinks";

const popUpBackgroundStyle = {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: "1",
    left: "0",
    top: "0",
}

const popUpBoxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "400px",
    height: "630px",
    marginLeft: "-200px",
    marginTop: "-300px",
    background: "#fff",
    borderRadius: "11px",
    fontFamily: "Tahoma, Geneva, sansSerif",
    fontSize: "16px",
    textAlign: "center",           
    color: "#000",
}

const popUpBoxStyleExclu = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "300px",
    height: "180px",
    marginLeft: "-150px",
    marginTop: "-90px",
    background: "#fff",
    fontFamily: "Tahoma, Geneva, sansSerif",
    fontSize: "16px",
    textAlign: "center",           
    color: "#000",
}

export class PopUp extends Component {
    render() {
        if(this.props.content === "EXCLUSIVE CONTENT") {
            return (
                <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                    <div  style={popUpBoxStyleExclu}>
                        <ExclusiveContent/>
                    </div>
                </div>
            );
        }

        if(this.props.content === "SHOWS") {
            return (
                <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                    <div style={popUpBoxStyle}>
                    <Shows/>
                    </div>
                </div>
            );
        }

        if(this.props.content === "STORE") {
            return (
                <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                    <div style={popUpBoxStyle}>
                    <Store/>
                    </div>
                </div>
            );
        }

        if(this.props.content === "LISTEN") {
            if(this.props.tab === "QUALITY CONTROL"){
                return (
                    <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                        <div style={popUpBoxStyle}>
                        <MusicLinks tab={"QUALITY CONTROL"}/>
                        </div>
                    </div>
                );
            }

            if(this.props.tab === "TWISTED FRUIT"){
                return (
                    <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                        <div style={popUpBoxStyle}>
                        <MusicLinks tab={"TWISTED FRUIT"}/>
                        </div>
                    </div>
                );
            }

            if(this.props.tab === "GOTHAM"){
                            return (
                                <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                                    <div style={popUpBoxStyle}>
                                    <MusicLinks tab={"GOTHAM"}/>
                                    </div>
                                </div>
                            );
                        }
                        
            if(this.props.tab === "INTRO"){
                return (
                    <div className="pop-up-background" onClick={this.props.closePopUp} style={popUpBackgroundStyle}>
                        <div style={popUpBoxStyle}>
                        <MusicLinks tab={"INTRO"}/>
                        </div>
                    </div>
                );
            }
        }
    }
}

export default PopUp;